<template>
  <v-container>
    <div
      class="profile scroll-container"
      v-if="
        isAuthenticated && profileLevel >= minProfileLevel && !isFacilitatedUser
      "
    >
      <h1>Richiesta agevolazione clienti invalidi</h1>
      <v-row
        ><v-col
          ><p>
            I clienti invalidi possono usufruire di agevolazioni di consegna per
            il servizio "A casa tua", nello specifico
            <b
              >due consegne gratuite al mese (fa riferimento la data di
              inserimento ordine).</b
            ><br /><br />
            L’agevolazione verrà attribuita ai clienti con un grado di
            invalidità pari o superiore al 60%. Per usufruire dell'agevolazione
            occorre compilare il modulo, allegando copia del
            <b>Verbale della Commissione Medica ad uso aziendale</b> (unico
            documento idoneo) attestante la percentuale di invalidità senza
            indicazione della relativa patologia. <br /><br />Per usufruire
            dell'agevolazione, il nominativo indicato nel profilo Spesa Online
            deve combaciare con quello presente sul Verbale caricato.
            <br /><br />
            L'attivazione dell'agevolazione richiede un tempo tecnico di
            lavorazione dei dati forniti e può essere interrotta in caso di
            mancato riscontro delle informazioni inserite.
          </p>
        </v-col>
      </v-row>
      <v-form ref="personDataRef" v-model="personData">
        <h2>Seleziona l'indirizzo</h2>
        <p>
          Seleziona o inserisci l'indirizzo di consegna a cui abbinare
          l'agevolazione di invalidità.
        </p>
        <v-row>
          <v-col cols="12" md="5">
            <v-select
              :items="addresses"
              v-model="addressModel"
              item-text="addressName"
              item-value="addressId"
              label="Seleziona un indirizzo"
              dense
              outlined
              @change="changeAddress"
            >
              <template v-slot:item="{ item }"
                ><v-row
                  ><v-col col="12"
                    ><b>
                      {{ item.addressName }}
                    </b>
                  </v-col>
                  <v-col cols="12">
                    <p>
                      {{ item.address1 }} {{ item.addressNumber }},
                      {{ item.postalcode }}, {{ item.city }},
                      {{ item.province }}
                    </p></v-col
                  ></v-row
                ></template
              ></v-select
            >
          </v-col>
          <v-col cols="12" md="1"> oppure </v-col>
          <v-col cols="12" md="6">
            <v-btn
              color="primary"
              large
              depressed
              @click="openAddAddress()"
              class="add-address"
            >
              AGGIUNGI INDIRIZZO
            </v-btn>
          </v-col>
        </v-row>
        <h2>Dati anagrafici:</h2>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="user.Name"
              label="Nome *"
              :id="'id_' + Math.random()"
              :rules="firstNameRules"
              required
              readonly
              disabled
              outlined
              dense
              name="firstName"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="user.Surname"
              label="Cognome *"
              :id="'id_' + Math.random()"
              :rules="lastNameRules"
              required
              outlined
              readonly
              disabled
              dense
              name="lastName"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="user.Email"
              label="Email *"
              type="email"
              :id="'id_' + Math.random()"
              :rules="emailRules"
              autocomplete="off"
              required
              outlined
              readonly
              disabled
              dense
              @change="emailModified = true"
              @blur="$refs.personDataRef.validate()"
              name="email"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-file-input
              ref="uploader"
              outlined
              background-color="white"
              placeholder="Nessun file selezionato"
              v-model="attachmentModel"
              dense
              prepend-icon="mdi-cloud-upload"
              id="uploader"
              accept="*"
              label="Documento ASL *"
            ></v-file-input>
            <p color="$text-color" style="font-size: 13px; opacity: 0.6;">
              Dimensione massima: 5MB <br />(formati: doc, docx, pdf, jpg, jpeg,
              png, odt)
            </p>
          </v-col>
          <!-- <v-col cols="12" md="3" sm="2">
            <v-btn
              color="primary"
              @click.prevent="handleFileImport"
              large
              depressed
              :loading="isSelecting"
            >
              Carica documento
            </v-btn>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="descriptionModel"
              outlined
              background-color="white"
              dense
              label="Note (facoltativo)"
            >
            </v-textarea>
          </v-col>
        </v-row>
        <div class="d-flex privacy-section flex-column" v-if="isUpdate">
          <h2>Testi informativa e consenso:</h2>
          <p>
            <span>
              <router-link
                :to="{
                  name: 'Page',
                  params: { pageName: 'consegna-agevolata' }
                }"
                class="no-underline"
              >
                Leggi l’informativa sul trattamento dei dati
              </router-link></span
            >
          </p>
          <v-checkbox
            color="primary"
            class="ma-0 mb-2 privacy-check"
            hide-details
            required
            v-model="user.ConsentFacilitatedDelivery"
            :label="$t('profile.consensoDisabili')"
          >
          </v-checkbox>
        </div>
        <v-alert type="error" v-if="imgErrorString">{{
          $t("disabili.imgTooBigError")
        }}</v-alert>
      </v-form>
      <ResponseMessage :response="response" />
      <div cols="12">
        <template>
          <v-btn
            class="bg-white mr-2"
            color="primary"
            outlined
            to="/"
            large
            depressed
          >
            {{ $t("profile.cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            @click.prevent="handleUserDataSubmit"
            large
            depressed
            :disabled="disabledUpdate"
            :loading="loadingUpdate"
          >
            Invia
          </v-btn>
        </template>
      </div>
    </div>
    <div v-else-if="isFacilitatedUser">
      <div>
        <h1>Richiesta agevolazione clienti invalidi</h1>
        <v-row
          ><v-col
            ><p>
              I clienti invalidi possono usufruire di agevolazioni di consegna
              per il servizio "A casa tua", nello specifico
              <b
                >due consegne gratuite al mese (fa riferimento la data di
                inserimento ordine).</b
              ><br /><br />
              L’agevolazione verrà attribuita ai clienti con un grado di
              invalidità pari o superiore al 60%. Per usufruire
              dell'agevolazione occorre compilare il modulo, allegando copia del
              <b>Verbale della Commissione Medica ad uso aziendale</b> (unico
              documento idoneo) attestante la percentuale di invalidità senza
              indicazione della relativa patologia. <br /><br />Per usufruire
              dell'agevolazione, il nominativo indicato nel profilo Spesa Online
              deve combaciare con quello presente sul Verbale caricato.
              <br /><br />
              L'attivazione dell'agevolazione richiede un tempo tecnico di
              lavorazione dei dati forniti e può essere interrotta in caso di
              mancato riscontro delle informazioni inserite.
            </p>
          </v-col>
        </v-row>
      </div>
      <div>
        <v-row>
          <v-col cols="12"
            ><p class="already_registered">
              Hai già effettuato una richiesta o hai già un’agevolazione attiva
              per questa utenza.<br />
              Se vuoi effettuare delle modifiche contatta il servizio clienti.
            </p></v-col
          >
        </v-row>
        <v-row>
          <v-col cols="12" v-if="isValid">
            <p class="already_registered"><b>Stato agevolazione: </b>attiva</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" v-if="toValidate">
            <p class="already_registered">
              <b>Stato agevolazione: </b>in validazione
            </p>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-else>
      <div>
        <h1>Richiesta agevolazione clienti invalidi</h1>
        <v-row>
          <v-col cols="12"
            ><p>
              I clienti invalidi possono usufruire di agevolazioni di consegna
              per il servizio "A casa tua", nello specifico
              <b
                >due consegne gratuite al mese (fa riferimento la data di
                inserimento ordine).</b
              ><br />
              <br />
              L’agevolazione verrà attribuita ai clienti con un grado di
              invalidità pari o superiore al 60%. Per usufruire
              dell'agevolazione occorre compilare il modulo, allegando copia del
              <b>Verbale della Commissione Medica ad uso aziendale</b> (unico
              documento idoneo) attestante la percentuale di invalidità senza
              indicazione della relativa patologia. <br /><br />Per usufruire
              dell'agevolazione, il nominativo indicato nel profilo Spesa Online
              deve combaciare con quello presente sul Verbale caricato.
              <br /><br />L'attivazione dell'agevolazione richiede un tempo
              tecnico di lavorazione dei dati forniti e può essere interrotta in
              caso di mancato riscontro delle informazioni inserite.
            </p></v-col
          >
          <v-col cols="12" v-if="!isAuthenticated"
            >Effettua il login per poter accedere alla pagina di richiesta
            agevolazioni in caso di invalidità.</v-col
          >
          <v-col
            cols="12"
            v-if="isAuthenticated && profileLevel < minProfileLevel"
          >
            Completa il tuo profilo per poter accedere alla pagina di richiesta
            agevolazioni in caso di invalidità.
          </v-col>
          <v-col v-if="isAuthenticated && profileLevel < minProfileLevel"
            ><v-btn
              color="primary"
              class="login-button"
              large
              depressed
              to="/profile/profile-update"
            >
              {{ $t("checkout.completeProfileTitle") }}
            </v-btn></v-col
          >
          <v-col v-else
            ><v-btn
              color="primary"
              class="login-button"
              large
              depressed
              @click.stop="handleLogin()"
            >
              {{ $t("login.buttonLogin") }}
            </v-btn></v-col
          >
        </v-row>
      </div>
    </div>
  </v-container>
</template>
<style lang="scss">
.privacy-check label {
  font-size: 14px;
  color: $text-color !important;
  line-height: 1;
}
.already_registered {
  font-size: 24px;
}
.profile {
  .v-col {
    padding-bottom: 0;
    .v-text-field__details {
      margin-bottom: 1px;
    }
  }
  .v-autocomplete {
    .v-input__append-inner {
      display: none;
    }
  }
  .v-input__slot {
    background-color: $white !important;
  }
  .v-input__prepend-outer {
    position: relative;
    // left: 0px;
    // z-index: 2;
    // display: none;
  }
  .privacy-section {
    margin-bottom: 30px;
    .v-input__slot {
      background-color: transparent !important;
    }
    // .phone-section {
    //   .v-input__slot {
    //     background-color: $white !important;
    //   }
    // }
  }
}
</style>
<script>
// @ is an alias to /src
import StoreService from "~/service/storeService";
import IperalCustomService from "@/service/iperalCustomService";
import { requiredValue, isEmail } from "@/validator/validationRules";
// import SecurityService from "~/service/securityService";
import ResponseMessage from "@/components/ResponseMessage";
import login from "~/mixins/login";

import { mapGetters, mapState } from "vuex";
import { mask } from "vue-the-mask";
export default {
  name: "ConsegneDisabili",
  components: {
    ResponseMessage
  },
  mixins: [login],
  directives: { mask },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    disabledUpdate() {
      return (
        !this.personData ||
        !this.attachmentModel ||
        !this.user.ConsentFacilitatedDelivery ||
        !this.addressModel
      );
    },
    ...mapGetters({
      profileLevel: "cart/profileLevel",
      sessionUser: "cart/getUser",
      isAuthenticated: "cart/isAuthenticated"
    }),
    isUpdate() {
      return this.profileLevel > 1;
    }
  },
  data() {
    return {
      dialog: false,
      response: {},
      menu: false,
      user: null,
      addresses: [],
      attachmentModel: null,
      descriptionModel: null,
      addressModel: null,
      personData: false,
      imgErrorString: false,
      emailConfirm: null,
      emailModified: false,
      lazy: true,
      loadingUpdate: false,
      minProfileLevel: global.config.minProfileLevel,
      firstNameRules: [requiredValue("Digitare il nome")],
      lastNameRules: [requiredValue("Digitare il cognome")],
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      emailConfirmRules: [
        requiredValue("Confermare l'email"),
        v => v === this.user.Email || "Le email non coincidono"
      ],
      requiredRules: [requiredValue()],
      isSelecting: false,
      isFacilitatedUser: false,
      isValid: false,
      toValidate: false
    };
  },
  methods: {
    changeAddress() {
      var _this = this;
      _this.addressModelCopy = _this.addressModel;
    },
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    handleFileImport() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      // Trigger click on the FileInput
      //this.$refs.uploader.$el.click();
      document.getElementById("uploader").click();
    },
    async handleUserDataSubmit(e) {
      try {
        var _this = this;
        console.log(_this);
        e.preventDefault();
        this.loadingUpdate = true;

        if (!this.$refs.personDataRef.validate()) {
          this.loadingUpdate = false;
          return;
        }
        if (this.$refs.personDataRef.validate()) {
          //file deve essere minore di 2mb
          if (this.attachmentModel && this.attachmentModel.size / 1024 > 5120) {
            this.imgErrorString = true;
          } else {
            this.imgErrorString = false;

            var paramUser = {};
            var regMod = {};
            let fidelityCardCode = {};
            let regStatus = {};
            let regDisclaimerList = [];
            let regDisclaimer = {};
            let regModuleAction = {};
            let infos = [];

            fidelityCardCode.code = this.user.CardCode;
            regMod.userId = this.cart.user.userId;
            regMod.firstName = this.user.Name;
            regMod.lastName = this.user.Surname;
            regMod.email = this.user.Email;
            regMod.fidelityCard = fidelityCardCode;
            regMod.personId = this.cart.user.person.personId;
            regStatus.registrationModuleStatusId = 0;
            regMod.registrationModuleStatus = regStatus;
            regDisclaimer.value = "true";
            regDisclaimer.registrationDisclaimer = {};
            regDisclaimer.registrationDisclaimer.registrationDisclaimerId = 1;
            regModuleAction.registrationModuleActionId = 999;
            regModuleAction.registrationModuleInfoTypeId = 3;
            regModuleAction.name = "Conferma consegna agevolata";
            regModuleAction.active = 1;
            regMod.registrationModuleAction = regModuleAction;
            let moduleType = {};

            paramUser.userId = this.cart.user.userId;
            paramUser.firstName = this.user.Name;
            paramUser.lastName = this.user.Surname;
            paramUser.email = this.user.Email;
            paramUser.fidelityCard = this.user.CardCode;
            paramUser.login = this.user.Email;
            moduleType.registrationModuleTypeId = 3;
            regDisclaimerList.push(regDisclaimer);
            regMod.registrationModuleType = moduleType;
            regMod.registrationModuleDisclaimers = regDisclaimerList;
            paramUser.registrationModule = regMod;
            paramUser.personId = this.cart.user.person.personId;

            if (this.attachmentModel) {
              let documents = [];
              let document = {};

              document.contentText = await this.toBase64(_this.attachmentModel);

              document.fileName = _this.attachmentModel.name;
              document.mimeType = _this.attachmentModel.type;
              documents.push(document);
              paramUser.registrationModule.documents = documents;
            }
            if (this.descriptionModel) {
              let note = {};
              note.value = this.descriptionModel;
              note.registrationModuleInfoType = {};
              note.active = 1;
              note.registrationModuleInfoType.registrationModuleInfoTypeId = 1;
              // note.active = 1;
              infos.push(note);
              paramUser.registrationModule.infos = infos;
            }

            if (this.addressModel) {
              let address = {};
              address.value = this.addressModel;
              address.active = 1;
              address.registrationModuleInfoType = {};
              address.registrationModuleInfoType.registrationModuleInfoTypeId = 998;
              infos.push(address);
              paramUser.registrationModule.infos = infos;
            }

            let registrationModuleDisclaimers = [];
            let disclaimer = {};
            // disclaimer.registrationDisclaimerId = 1;
            disclaimer.value = registrationModuleDisclaimers.push(
              this.user.ConsentFacilitatedDelivery
            );

            let res = await IperalCustomService.sendFacilitatedDeliveryData(
              paramUser
            );

            if (res && res.status == 0) {
              this.$router.push({
                name: "FacilitatedDeliverySuccessful"
              });
            }
          }
        }
      } catch (r) {
        console.log(r);
        this.response = r;
      } finally {
        this.loadingUpdate = false;
      }
    },
    closeConfirm() {
      this.$router.push("/");
    },
    async fetchUserData() {
      this.user = await IperalCustomService.getUser();
    },
    async checkIfAlreadyFacilitated(user, registrationModuleTypeId) {
      let res = await IperalCustomService.checkIfAlreadyFacilitated(
        user.userId,
        registrationModuleTypeId
      );
      if (res.response && res.response.status == 1) {
        this.isFacilitatedUser = true;
        if (
          res.data.value &&
          res.data.value.status &&
          (res.data.value.status == "TO_VALIDATE" ||
            res.data.value.status == "IN_VALIDATION")
        ) {
          this.toValidate = true;
        } else {
          this.toValidate = false;
        }
        if (
          res.data.value &&
          res.data.value.status &&
          res.data.value.status == "VALID"
        ) {
          this.isValid = true;
        } else {
          this.isValid = false;
        }
      } else {
        this.isFacilitatedUser = false;
      }
    },
    async handleLogin() {
      if (await this.needLogin()) {
        if (await this.needAddress()) {
          await this.needTimeslot();
        }
      }
      if (this.isAuthenticated) await this.fetchUserData();
    },
    async fetchAddresses() {
      var _this = this;
      _this.addresses = await StoreService.getAddressesByDeliveryService(2);
    },
    openAddAddress() {
      this.$router.push({
        path: "/profile/addresses/manage"
      });
      this.$emit("submit", false);
    }
  },
  async mounted() {
    // http://localhost:8080/ebsn/api/iperal-module-controller/control?user_id=358477&registration_module_type_id=999
    if (this.isAuthenticated) {
      this.checkIfAlreadyFacilitated(this.sessionUser, 3);
      await this.fetchUserData();
      await this.fetchAddresses(this.cart.shippingAddress.deliveryServiceId);
    }
  },
  watch: {
    isAuthenticated: async function() {
      if (this.cart && this.cart.shippingAddress) {
        this.checkIfAlreadyFacilitated(this.sessionUser, 3);
        this.fetchAddresses(this.cart.shippingAddress.deliveryServiceId);
      }
    }
  }
};
</script>
